<template>
	<!-- unselectable="on"
		onselectstart="return false;"
		onmousedown="return false;" -->
	<div id="app" class="text-gray-600 bg-gray-900">
		<Navbar></Navbar>
		<router-view></router-view>
		<Footer></Footer>
		<!-- <div
      class="fixed z-50 right-1 bottom-1 text-sm px-2 py-0 rounded-md text-black bg-green-400 font-work tracking-widest"
    >
      <div class="block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">
        sm
      </div>
      <div class="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
        sm
      </div>
      <div class="hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden">
        md
      </div>
      <div class="hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden">
        lg
      </div>
      <div class="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden">
        xl
      </div>
      <div class="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block">
        xl
      </div>
    </div> -->
	</div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
export default {
	components: {
		Navbar,
		Footer
	}
};
</script>
<style>
#app {
	font-family: "Montserrat", sans-serif;
	/* font-family: Avenir, Helvetica, Arial, sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
